import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CSectTitle,
  LPickupList,
  LRestaurant,
  LRestaurantContact,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: (
              <>
                RESTAURANT <br className="u_sp" />
                PLAN
              </>
            ),
            sub: 'プロポーズ・サプライズ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/restaurants_plan/propose/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/restaurants_plan/propose/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'お祝い・記念日',
                path: '/anniversary/',
              },
              {
                label: 'お祝い・記念日 レストランプラン',
                path: '/anniversary/restaurants_plan/',
              },
            ],
            current: {
              label: 'プロポーズ・サプライズ',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mtMedium u_mbExLarge">
        <LWrap>
          <p className="c_sectLead">
            高層階からの夜景と美食を堪能するレストラン。
            <br />
            ロマンティックな雰囲気と心地よいおもてなしで、ラグジュアリーなひとときを。
          </p>
          <CSectTitle
            title={{
              ja: 'プロポーズ・サプライズ',
              en: (
                <>
                  RESTAURANT <br className="u_sp" />
                  PLAN
                </>
              ),
            }}
          />
          <LPickupList
            classification={['レストランプロポーズ・サプライズ']}
            btnlabel="メニューの詳細・ご予約"
            contact=""
          />
        </LWrap>
        <CBtnList
          exClass="u_mtExLarge"
          data={[
            {
              label: 'お祝い・記念日　レストランプラントップ',
              link: {
                href: '/anniversary/restaurants_plan/',
              },
            },
          ]}
        />
      </section>
      <section className="l_sect u_pb0">
        <LRestaurant />
      </section>
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
